@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", "Helvetica Neue", sans-serif;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.carousel > img {
  max-width: 80%;
  max-height: 500px;
}

.carousel-smaller {
  width: 50%;
}

.carousel-smaller > div {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: inline-block;
  margin: 15px;
  cursor: pointer;
  border: 2px solid #333;
  overflow: hidden;
}

.carousel-smaller > img.active {
  border-color: #333;
  opacity: 0.6;
}

@media only screen and (max-width: 600px) {
  .image-container {
    margin: 0 auto;
    float: none;
  }
  .carousel-smaller > img {
    width: 60px;
    height: 60px;
  }
  .photo {
    max-width: 100%;
  }
  .section-content {
    flex-direction: column;
  }
  .section-content p:last-child {
    padding: 0;
  }
  .splash-image {
    height: 300px;
  }
}
